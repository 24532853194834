import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import SwaggerUI from 'swagger-ui-react';

import { ApiDocsPageQuery } from '../types/graphql';
import Layout from './layout';

import docs from '../../static/api/docs/swagger.yml';
import 'swagger-ui-react/swagger-ui.css';

interface ApiDocsPage {
  data: {
    defaultHeader: ApiDocsPageQuery['defaultHeader'];
    defaultFooter: ApiDocsPageQuery['defaultFooter'];
  };
}

const ApiDocsPage: React.FC<ApiDocsPage> = ({ data }) => (
  <Layout
    headerData={data.defaultHeader}
    footerData={data.defaultFooter}
    alternateLanguages={[]}
  >
    <Helmet title="API Documentation - DEKEMA" />

    <section style={{ background: 'white' }}>
      <SwaggerUI spec={docs} />
    </section>
  </Layout>
);

export default ApiDocsPage;

export const query = graphql`
  query ApiDocsPage {
    defaultHeader: markdownRemark(
      fields: { collection: { eq: "header" }, lang: { eq: "en" } }
    ) {
      ...Header
    }

    defaultFooter: markdownRemark(
      fields: { collection: { eq: "footer" }, lang: { eq: "en" } }
    ) {
      ...Footer
    }
  }
`;
